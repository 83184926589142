import ForgotCard from "components/login/ForgotCard";
import LoginCard from "components/login/LoginCard";
import colors from "constants/colors";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestResetPassword } from "Store/user/actions";
import { makeSelectLocation, makeSelectUserIsLogged } from "Store/user/selectors";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);

  const currentLocation = useSelector(makeSelectLocation);
  const isLogged = useSelector(makeSelectUserIsLogged);

  const handleForgotPassword = (email: string) => {
    dispatch(requestResetPassword({ email }));
  };

  return (
    !isLogged && (
      <div className="login-card">
        {currentLocation && currentLocation.branding && currentLocation.branding.color_logo && (
          <img
            className="login-logo"
            src={
              currentLocation && currentLocation.branding ? currentLocation.branding.color_logo : ""
            }
            alt="Login Logo"
          />
        )}
        {forgotPassword ? (
          <ForgotCard
            email={email}
            handleBack={setForgotPassword}
            handleEmail={setEmail}
            handleForgotPassword={handleForgotPassword}
          />
        ) : (
          <LoginCard
            email={email}
            password={password}
            handleEmail={setEmail}
            handlePassword={setPassword}
            handleBack={setForgotPassword}
          />
        )}
        <div className="login-footer">
          <p style={{ textAlign: "justify", padding: "0 10px", color: colors.grey }}>
            SAT® and ACT® are registered trademarks of College Board and ACT, Inc. respectively.
            Neither organization was involved in the creation of, nor has endorsed, or sponsored
            this product.
          </p>
        </div>
      </div>
    )
  );
};

export default Login;
