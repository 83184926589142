import Button from "components/Shared/Button/Button";
import Input from "components/Shared/Input/Input";
import { INFOS } from "constants/localization/info";
import React from "react";
import { useSelector } from "react-redux";
import { makeSelectActionSpinner } from "Store/global/selectors";
interface IProps {
  email: string;
  handleEmail: (email: string) => void;
  handleForgotPassword: (email: string) => void;
  handleBack: (arg: boolean) => void;
}
export const Forgot: React.FC<IProps> = ({
  email,
  handleEmail,
  handleForgotPassword,
  handleBack
}) => {
  const actionSpinner = useSelector(makeSelectActionSpinner);
  return (
    <div className="login-cards">
      <div className="login-description">
        <h5 className="login-header">Forgot Password</h5>
        <p className="login-about">{INFOS.handleResetPassword}</p>
      </div>
      <div className="login-form">
        <Input
          id="email"
          type="email"
          label="Email"
          placeholder="Enter your Email Address"
          value={email}
          onChange={handleEmail}
          icon="at"
        />

        <Button
          name="Reset Password"
          onClick={() => handleForgotPassword(email)}
          className="login-button"
          primary
          isLoading={actionSpinner}
          style={{ width: "100%", marginTop: "25px" }}
        />

        <Button
          name="Back To Login"
          className="login-button"
          onClick={() => handleBack(false)}
          style={{ width: "100%", marginTop: "25px" }}
        />
      </div>
    </div>
  );
};
export default Forgot;
