import { useCallback, useEffect } from "react";

export type Key =
  | "ArrowUp"
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "Enter"
  | "Delete"
  | "Escape"
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "."
  | ","
  | "/"
  | "*"
  | "-"
  | "+"
  | "%"
  | "="
  | " ";

type Falsy = false | undefined | null;

export const useKeyboardPress = (
  params?: Falsy | Partial<Record<Key, (key: Key, ev: KeyboardEvent) => void>>
) => {
  const handleKeyDown = useCallback(
    (ev: KeyboardEvent) => {
      let { key } = ev;

      params?.[key]?.(key, ev);
    },
    [params]
  );

  const clear = useCallback(() => {
    document.body.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (params) {
      document.body.addEventListener("keydown", handleKeyDown);

      return clear;
    }
  }, [handleKeyDown, clear, params]);
};
