import React, { FC, forwardRef, InputHTMLAttributes } from "react";
import Icon from "components/Shared/Icons/Icon/Icon";
import colors from "constants/colors";
import { v4 as uuid } from "uuid";

type Props<L, R> = R & Pick<L, Exclude<keyof L, keyof R>>;

interface PropsExtra {
  icon?: IconType;
  onChange: (value: string) => void;
  label?: string;
}

interface IProps extends Props<InputHTMLAttributes<HTMLInputElement>, PropsExtra> {}

const Input: FC<IProps & { ref?: React.Ref<HTMLInputElement> }> = forwardRef(
  (
    { icon, placeholder, type, value, style, min, max, id, className, onChange, maxLength, label },
    ref
  ) => {
    const handleInputChange = (event): void => {
      event.preventDefault();
      onChange(event.target.value);
    };

    return (
      <div>
        {label && <p className="text">{label}</p>}
        <div className={`form-input form-date-picker ${className || ""}`} id={id}>
          <input
            placeholder={placeholder || label}
            type={type || `text`}
            value={value}
            onChange={handleInputChange}
            style={style}
            min={min}
            max={max}
            data-tid={id}
            ref={ref}
            maxLength={maxLength}
          />
          {icon && (
            <div className="custom-form-input-icon">
              <Icon icon={icon as IconType} className="date-picker-icon" color={colors.grey} />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
