export const INFOS = {
  handleSubmitAnswersTestSection: `Are you sure you are ready to submit your answers for this test section? Note: If this is your first practice test, you will not be able to change answers on this section after clicking the 'Submit Answers' button below`,
  handleResetPassword: `If you’ve lost or forgotten your password, we can reset it through your email address. To
  receive a password reset by email, enter your email address below and click “Reset
  Password”`,
  handleSendToken: `A password token will be sent to your email. Use that token to update your password.`,
  handleContact: `Please contact us to set up a time to review your practice test.`,
  noSections: `There are no assigned sections for this test. Please contact your instructor to have more test sections assigned.`,
  enterInitials: `Please Enter Your Initials to Confirm`,
  handleReadingContent: (
    startingPage,
    endingPage
  ) => `I have read the assigned workbook page(s) ${startingPage}
  ${endingPage > startingPage ? `-${endingPage}` : ""} and
  completed any exercises or drills therein to the best of my ability. Further, I
  understand that initialing below without having read the assigned page(s) is unlikely to
  fool my instructor, who will be disappointed to learn of the deception.`,
  handleCompleted: `By Pressing Confirm answers will be submitted, and Section/Drill will be marked as Complete`,
  loginAbout: `For security purposes, you need to set a new password for this account. Please
  select a password between 8 and 24 characters and enter it below.`
};
