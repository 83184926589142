import Button from "components/Shared/Button/Button";
import Input from "components/Shared/Input/Input";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeSelectActionSpinner } from "Store/global/selectors";
import { handleLogin } from "Store/user/actions";
import { useKeyboardPress } from "utils/hooks/useKeyboardPress";

interface IProps {
  email: string;
  password: string;
  handleEmail: (email: string) => void;
  handlePassword: (password: string) => void;
  handleBack: (arg: boolean) => void;
}

const LoginCard: React.FC<IProps> = ({
  email,
  password,
  handleEmail,
  handlePassword,
  handleBack
}) => {
  const dispatch = useDispatch();
  const actionSpinner = useSelector(makeSelectActionSpinner);

  const handlePressEnter = (e) => {
    !!email && !!password ? handleSubmit(e) : null;
  };

  useKeyboardPress({
    Enter: handlePressEnter
  });

  const handleSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    dispatch(
      handleLogin({
        email,
        password
      })
    );
  };
  return (
    <div className="login-cards">
      <div className="login-description">
        <h5 className="login-header">Login</h5>
      </div>
      <div className="login-form">
        <Input
          id="email"
          type="text"
          placeholder="Email"
          label="Email"
          value={email}
          onChange={handleEmail}
          icon="user"
        />
        <Input
          id="password"
          type="password"
          label="Password"
          placeholder="Password"
          value={password}
          onChange={handlePassword}
          icon="key"
        />
      </div>
      <Button
        name="Login"
        className="login-button"
        onClick={handleSubmit}
        primary
        isLoading={actionSpinner}
        disabled={actionSpinner}
        style={{ width: "100%", marginTop: "25px" }}
      />
      <a onClick={() => handleBack(true)} className="login-link-forgot">
        Forgot Password?
      </a>
    </div>
  );
};

export default LoginCard;
